import store from '../store'

export default {
    baseUrl: process.env.VUE_APP_API_URL,
    getWorkingPlanning () {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.get(this.baseUrl + 'api-m/einstein/employee/workdays', config)
    },
    addWorkingDays (params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.post(this.baseUrl + 'api-m/einstein/employee/workdays', params, config)
    },
    getPlanning (dateDebut, dateFin) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let route = this.baseUrl + 'api-m/einstein/planning/data-creneaux' + (dateDebut ? '?date_debut=' + dateDebut : '') + (dateFin ? '&date_fin=' + dateFin : '')
        return window.axios.get(route, config)
    },
    cancelPlanning(params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.post(this.baseUrl + 'api-m/einstein/planning/mail-annulation', params, config)
    },
    getNextInterventionByContract(idContract) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let route = this.baseUrl + 'api-m/einstein/planning/prochaine-intervention?idcontract=' + idContract
        return window.axios.get(route, config)
    },
    cancelPastSlot(params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let route = this.baseUrl + 'api-m/einstein/planning/cancel-agenda-slot'
        return window.axios.post(route, params, config)
    },
    acceptSlotChange(params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let route = this.baseUrl + 'api-m/einstein/actions/accept-slot-change'
        return window.axios.post(route, {idslot: params}, config)
    },
    rejectSlotChange(params) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let route = this.baseUrl + 'api-m/einstein/actions/reject-slot-change'
        return window.axios.post(route, {idslot: params}, config)
    }
}
