<template>
    <b-modal ref="stopPrestation" hide-header hide-footer centered>
        <template v-slot:default>
            <div class="form-group p-3">
                <!--<h5 class="card-title text-center m-0" v-if="step === 'stop'">Est-ce que tout s’est bien déroulé ?</h5>
                <h5 class="card-title text-center m-0" v-else>Quand souhaitez-vous etre rappelé par MERCI+ ?</h5>-->
                <h5 class="card-title text-center m-0">Êtes-vous sûr(e) de vouloir clôturer la prestation ?</h5>
            </div>
            <div class="row no-gutters" v-if="step === 'stop'">
                <!--<div class="col mr-3">
                    <b-button size="lg" class="btn-outline-info w-100 w-100 askForACallBtn text-white" @click="step = 'callback'">
                        NON
                    </b-button>
                </div>
                <div class="col">
                    <b-button size="lg" class="btn-success w-100" @click="stopPrestation">
                        OUI
                    </b-button>
                </div>-->
                <div class="col-12 d-flex justify-content-between px-3 mb-3 align-items-center" v-if="isGfs">
                    <label for="" style="white-space: nowrap" class="mr-2">Kilomètres à facturer :</label>
                    <input class="form-control" style="max-width: 30vw" type="number" v-model="kms" min="0">
                </div>
<!--                <div class="col-12 d-flex justify-content-between px-3 mb-3 align-items-center" v-if="isGfs">-->
<!--                    <label for="" style="white-space: nowrap" class="mr-2">Paniers repas :</label>-->
<!--                    <input class="form-control" style="max-width: 30vw" type="number" v-model="lunchbox" min="0"-->
<!--                           max="3">-->
<!--                </div>-->
                <div class="col mr-3">
                    <b-button size="lg" class="btn-outline-info w-100 w-100 askForACallBtn text-white" @click="close">
                        NON
                    </b-button>
                </div>
                <div class="col">
                    <b-button size="lg" class="btn-success w-100" @click="stopPrestation">
                        OUI
                    </b-button>
                </div>
            </div>
            <div v-else>
                <div class="form-group row align-items-center justify-content-between">
                    <div class="col-auto">Heure*</div>
                    <div class="col-auto">
                        <b-form-select id="selectCallback" v-model="callbackTime"
                                       :options="timeOptions"></b-form-select>
                    </div>
                </div>
                <a href="#home" @click="callBackRequest" class="d-block">
                    <div class="card bg-success text-white">
                        <div class="card-body">
                            <h5 class="d-flex align-items-center justify-content-center m-0">
                                <span class="d-block text-truncate">Je demande à être rappelé</span>
                            </h5>
                        </div>
                    </div>
                </a>
            </div>
        </template>
    </b-modal>
</template>

<script>
import router from '../../../../router'
import pointageService from "../../../../services/pointageService";

export default {
    name: "StopPrestation",
    props: {
        idIntervention: {
            default: null
        }
    },
    watch: {
        lunchbox(val) {
            this.lunchbox = Math.min(Math.max(0, val), 3);
        }
    },
    data() {
        return {
            step: 'stop',
            callbackTime: 1,
            timeOptions: [
                {value: 1, text: 'Maintenant'},
                {value: 2, text: 'Dans une heure'},
                {value: 3, text: 'Dans deux heures'},
                {value: 4, text: 'Demain'}
            ],
            kms: 0,
            lunchbox: 0
        }
    },
    mounted() {
    this.initGeolocation();
        },
    computed: {
        isGfs() {
            return location.host.includes('family');
        }
    },
    methods: {
        async initGeolocation() {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            pos => resolve({ latitude: pos.coords.latitude, longitude: pos.coords.longitude }),
            err => reject(err)
          );
        });
        
        this.latitude = position.latitude;
        this.longitude = position.longitude;
      } catch (err) {
        console.error("Erreur de géolocalisation:", err);
      }
    },
        show() {
            this.$refs.stopPrestation.show()
        },
        close() {
            this.$refs.stopPrestation.hide()
        },
        callBackRequest(event) {
            event.preventDefault()
            // TODO: callback request
            this.close()
            router.push({name: 'home'})
        },
        async stopPrestation(event) {
            try {
            event.preventDefault()
            let params = {
                idintervention_ticking: this.idIntervention,
                end_time_real: this.$moment().format('HH:mm:ss'),
                kms: this.kms,
                lunchbox: this.lunchbox,
                latitude: this.latitude,
                longitude: this.longitude
            }
            this.close()

            pointageService.declarerDepart(params).then((response) => {
                               if (response.data.etat === 'OK') {
                    router.push({name: 'home'})
                } else {
                    this.$rollbar.error('Erreur 3059');
                    this.$bvToast.toast(response.data.data, {
                        title: 'Erreur 3059',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
            }).catch((error) => {
                this.$rollbar.error('Erreur 3060', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3060',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
            })
        }catch (err) {
        console.error("Une erreur est survenue :", err);
      }
        }
    }
}
</script>

<style scoped>
#selectCallback {
    background: #cbf0ff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%232f3744' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
}
</style>
