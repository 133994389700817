<template>
    <div id="debut-intervention-page" class="mb-6">
        <div class="text-center">
            <b-spinner v-if="load" type="grow" variant="danger" class="mt-5"></b-spinner>
        </div>
        <div v-if="!load">
            <!--status intervention-->
            <div class="text-white bg-info pt-3 pb-3 form-group container-fluid">
                <div class="form-group">
                    <h4>Intervention chez <span class="nameWrapper">{{ pointage.customer_lastname }} {{ pointage.customer_firstname }}</span></h4>
                    Durée de la prestation : <b class="dureeInterventionWrapper">{{ dureeIntervention }}H</b>
                </div>
                <div class="row justify-content-between align-items-center">
                    <!--<div class="col-auto">
                        <div class="bg-white p-2 rounded text-dark"><i class="fas fa-exclamation-triangle text-danger"></i> Pas
                            de dépassement</div>
                    </div>-->
                    <div class="col-auto">
                        <div class="bg-purple-corporate p-2 rounded text-center">
                            <i class="fas fa-lightbulb fa-2x"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card form-group">
                <div class="card-header bg-primary">
                    Vous avez démarré votre prestation à&nbsp;<b class="debutPrestationWrapper">{{ getFormattedHour(pointage.start_time_real) }}</b>
                </div>
                <div class="card-body">
                    <h5 class="card-title font-weight-bold nameBlock m-0"></h5>
                    <div class="text-center  form-group">
                        <h2 class="cursor-pointer finPrestationTitleWrapper m-0">Fin prévue à
                            <b class="finPrestationWrapper font-weight-bold">{{ end_time }}</b>
                        </h2>
                    </div>
                    <a id="endPrestationBtn" @click="stopPrestation" class="d-block cursor-pointer">
                        <div class="card bg-success text-white">
                            <div class="card-body">
                                <h5 class="d-flex align-items-center justify-content-center m-0">
                                    <i class="fas fa-stop-circle fa-2x mr-3"></i> <span class="d-block text-truncate">J'ai
                                    terminé la prestation</span>
                                </h5>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="card" v-if="customerInstructions">
                <div class="card-body">
                    <b>Consigne :</b> {{ customerInstructions }}
                </div>
            </div>
            <!--bouton voir fiche mission-->
            <!--<div class="list-group text-dark ">
                <router-link to="mission" class="list-group-item list-group-item-action">
                    <div class="centered-ico col-2 bg-primary"><i class="fas fa-eye fa-2x"></i></div>
                    <div class="align-items-center row text-dark">
                        <div class="col-8 text-center offset-2">
                            <span class="d-block text-truncate">Voir la fiche de mission</span>
                        </div>
                        <div class="col-2 text-right">
                            <i class="fas fa-chevron-right fa-2x"></i>
                        </div>
                    </div>
                </router-link>
            </div>-->
        </div>

        <!-- modal -->
        <StopPrestation ref="stopPrestation" :idIntervention="idIntervention"></StopPrestation>
    </div>
</template>

<script>
import StopPrestation from './Modals/StopPrestation'
import router from "../../../router";
import pointageService from "../../../services/pointageService";
import planningService from "../../../services/planningService";

export default {
    name: "Intervention",
    components: {
        StopPrestation
    },
    data() {
        return {
            idIntervention: null,
            pointage: null,
            load: true,
            customerInstructions: null,
            end_time: null,
            dureeIntervention: null
        }
    },
    mounted() {
        this.$emit('menu', 'pointages')
        this.idIntervention = router.currentRoute.params.idIntervention
        this.getPointage()
    },
    methods: {
        msToTime(duration) {
            let minutes = Math.floor((duration / (1000 * 60)) % 60)
            let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

            hours = (hours < 10) ? "0" + hours : hours
            minutes = (minutes < 10) ? "0" + minutes : minutes

            return hours + ":" + minutes
        },
        getDureeInter(date_debut, date_fin) {
            date_debut = new Date(this.$moment().format('YYYY-MM-DD') + ' ' + date_debut)
            date_fin = new Date(this.$moment().format('YYYY-MM-DD') + ' ' + date_fin)
            let diffTime = Math.abs(date_debut - date_fin)
            return this.msToTime(diffTime)
        },
        getInfosPrestation() {
            let end_time = this.$moment(this.$moment().format('YYYY-MM-DD') + ' ' + this.pointage.start_time_real).add(this.pointage.hours_each_intervention, 'h').format('HH:mm')
            planningService.getNextInterventionByContract(this.pointage.contract_idcontract).then((result) => {
                if (result.data.etat === 'OK') {
                    let data = result.data.data
                    let today = this.$moment().format('YYYY-MM-DD')
                    let date_prochaine_inter = this.$moment(data.date_slot).format('YYYY-MM-DD')
                    this.customerInstructions = data.customer_instructions;
                    if (today == date_prochaine_inter) {
                        this.dureeIntervention = this.getDureeInter(data.start_time, data.end_time)
                        this.end_time = this.$moment(this.$moment().format('YYYY-MM-DD') + ' ' + this.pointage.start_time_real).add(this.dureeIntervention, 'h').format('HH:mm')
                    } else {
                        this.end_time = end_time
                        this.dureeIntervention = this.pointage.hours_each_intervention
                    }
                }
            }).catch((error) => {
                console.log(error)
                this.end_time = end_time
                this.dureeIntervention = this.pointage.hours_each_intervention
            })
        },
        getFormattedHour(hour) {
            return this.$moment(this.$moment().format('YYYY-MM-DD') + ' ' + hour).format('HH:mm')
        },
        stopPrestation(event) {
            event.preventDefault()
            this.$refs.stopPrestation.show()
        },
        getPointage() {
            pointageService.getPointage(this.idIntervention).then((response) => {
                if (response.data.etat === 'OK') {
                    this.pointage = response.data.data
                    this.pointage.hours_each_intervention = parseFloat(this.pointage.hours_each_intervention).toFixed(0)
                } else {
                    this.$rollbar.error('Erreur 3057');
                    this.$bvToast.toast('Une erreur est survenue', {
                        title: 'Erreur 3057',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
                this.load = false
                this.getInfosPrestation()
            }).catch((error) => {
                    this.$rollbar.error('Erreur 3058', error);
                    this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        title: 'Erreur 3058',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                    this.load = false
                })
            }
        }
    }
</script>

<style scoped>

</style>
